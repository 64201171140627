import request from '@/plugin/axios';
import setting from '@/setting';
var hostPath = setting.appApi;

var pos = function pos(host, action, data) {
  if (!data) {
    data = {};
  }

  if (!data.hasOwnProperty('allSys')) {} // data.sys = setting.systemId
  // data.sys = 1


  return request({
    url: host + action,
    method: 'post',
    data: data
  });
};

export function accountCodeBind(data) {
  return pos(hostPath.base, 'tw/bind', data);
}
export function accountLogin(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account/login', data);
}
export function accountList(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account', data);
}
export function accountAdd(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account/handle', data);
}
export function accountDel(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account/delete', data);
}
export function menuList(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'system/menu', data);
}
export function menuBatch(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'system/menu/batch/handle', data);
}
export function menuAdd(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'system/menu/handle', data);
}
export function menuDel(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'system/menu/delete', data);
}
export function roleList(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account/roles', data);
}
export function roleAdd(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account/roles/handle', data);
}
export function roleDel(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'account/roles/delete', data);
}
export function systemTempList(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'tools/generator', data);
}
export function systemTempAdd(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'tools/generator/handle', data);
}
export function systemTempDel(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'tools/generator/delete', data);
}
export function systemTempDetail(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'tools/generator/detail', data);
}
export function systemTempDetailAdd(data) {
  data.allSys = 1;
  return pos(hostPath.base, 'tools/generator/detail/handle', data);
}
export function areaList(data) {
  return pos(hostPath.base, 'area', data);
}
export function areaListByPid(data) {
  return pos(hostPath.base, 'area/index', data);
}
export function areaAdd(data) {
  return pos(hostPath.base, 'area/handle', data);
}
export function areaDel(data) {
  return pos(hostPath.base, 'area/delete', data);
} //用户列表

export function userList(data) {
  return pos(hostPath.base, 'user', data);
}
export function userDel(data) {
  return pos(hostPath.base, 'user/delete', data);
}
export function userAdd(data) {
  return pos(hostPath.base, 'user/handle', data);
}
export function productCategory(data) {
  //产品分类
  return pos(hostPath.base, 'product/category', data);
}
export function productCategoryAdd(data) {
  //产品分类
  return pos(hostPath.base, 'product/category/handle', data);
}
export function productCategoryDel(data) {
  //产品分类
  return pos(hostPath.base, 'product/category/delete', data);
}
export function productAdd(data) {
  //产品添加
  return pos(hostPath.base, 'product/handle', data);
}
export function productDel(data) {
  //产品删除
  return pos(hostPath.base, 'product/delete', data);
}
export function productList(data) {
  //产品列表
  if (data.where.is_main === 0) {
    data.allSys = 1;
  }

  return pos(hostPath.base, 'product', data);
}
export function productPackage(data) {
  //产品套餐编辑
  return pos(hostPath.base, 'product/package/handle', data);
}
export function productBatchShow(data) {
  //产品批量上下架
  return pos(hostPath.base, 'product/online/batch', data);
}
export function orderList(data) {
  //订单列表
  return pos(hostPath.base, 'order', data);
}
export function orderProduct(data) {
  //订单编辑
  return pos(hostPath.base, 'order/product', data);
}
export function orderAdd(data) {
  return pos(hostPath.base, 'order/handle', data);
}
export function orderCancel(data) {
  //订单撤单
  return pos(hostPath.base, 'order/cancel', data);
}
export function adList(data) {
  //广告
  return pos(hostPath.base, 'slide', data);
}
export function adAdd(data) {
  //广告
  return pos(hostPath.base, 'slide/handle', data);
}
export function adDel(data) {
  //广告
  return pos(hostPath.base, 'slide/delete', data);
}
export function opLogs(data) {
  //系统操作日志
  return pos(hostPath.base, 'logs', data);
}
export function tradeFlow(data) {
  //交易流水
  data.allSys = 1;
  return pos(hostPath.base, 'flowing', data);
}
export function tradeFlowDeduction(data) {
  //不扣款
  return pos(hostPath.base, 'flowing/transaction', data);
}
export function configBalanceList(data) {
  //结算配置
  return pos(hostPath.base, 'balance', data);
}
export function configBalanceAdd(data) {
  return pos(hostPath.base, 'balance/handle', data);
}
export function configBalanceDel(data) {
  return pos(hostPath.base, 'balance/delete', data);
}
export function configGradeList(data) {
  //定级配置
  return pos(hostPath.base, 'grade/config', data);
}
export function configGradeAdd(data) {
  //定级配置
  return pos(hostPath.base, 'grade/config/handle', data);
}
export function configBonusList(data) {
  //奖金配置
  return pos(hostPath.base, 'bonus/config', data);
}
export function configStoreList(data) {
  //定级配置
  return pos(hostPath.base, 'store/config', data);
}
export function configLevelList(data) {
  //定级配置
  return pos(hostPath.base, 'level/config', data);
}
export function recommendList(data) {
  //推荐网
  data.allSys = 1;
  return pos(hostPath.base, 'user/relationship', data);
}
export function curRecommendList(data) {
  //实时的推荐网
  data.allSys = 1;
  return pos(hostPath.base, 'user/relationship', data);
}
export function placeList(data) {
  //链路图
  return pos(hostPath.base, 'user/place', data);
}
export function expectNumList(data) {
  //链路图
  return pos(hostPath.base, 'balance/total', data);
}
export function memberMulUpdateRegion(data) {
  //链路图
  return pos(hostPath.base, 'user/batch/direct', data);
}
export function memberMulUpdateState(data) {
  //批量更新用户状态
  return pos(hostPath.base, 'user/status/batch', data);
}
export function gradeMulUpdate(data) {
  //批量更新等级
  return pos(hostPath.base, 'grade/update', data);
}
export function levelMulUpdate(data) {
  //批量更新等级
  return pos(hostPath.base, 'user/level/update', data);
}
export function activeMulUpdate(data) {
  //批量更新等级
  return pos(hostPath.base, 'user/active', data);
}
export function gradeLog(data) {
  //定级日志
  return pos(hostPath.base, 'grade/logs', data);
}
export function levelLog(data) {
  //资格定级日志
  return pos(hostPath.base, 'level/logs', data);
}
export function staticTotal(data) {
  //统计
  return pos(hostPath.base, 'statistics/overview', data);
}
export function staticAllTotal(data) {
  //统计
  return pos(hostPath.base, 'statistics/total', data);
}
export function staticMemberAdd(data) {
  //统计
  return pos(hostPath.base, 'statistics/member', data);
}
export function staticIncome(data) {
  //统计
  return pos(hostPath.base, 'statistics/income', data);
}
export function staticGrade(data) {
  //统计
  return pos(hostPath.base, 'statistics/group', data);
}
export function staticProduct(data) {
  //统计
  return pos(hostPath.base, 'statistics/product', data);
}
export function staticPgv(data) {
  //统计
  return pos(hostPath.base, 'statistics/pgv', data);
}
export function sendBonusLog(data) {
  //奖金发放日志
  return pos(hostPath.base, 'bonus', data);
}
export function memberMoveNow(data) {
  //移动点位
  return pos(hostPath.base, 'move/handle', data);
}
export function memberMoveAdd(data) {
  //移动点位
  return pos(hostPath.base, 'move/handle', data);
}
export function memberMoveDel(data) {
  //移动点位
  return pos(hostPath.base, 'move/delete', data);
}
export function memberMoveList(data) {
  //移动点位
  return pos(hostPath.base, 'move', data);
}
export function memberRepairMoney(data) {
  //补扣款
  return pos(hostPath.base, 'finance/handle', data);
}
export function memberTakeList(data) {
  //提现申请
  return pos(hostPath.base, 'withdrawal', data);
}
export function memberTakeAdd(data) {
  //提现申请
  return pos(hostPath.base, 'withdrawal/handle', data);
}
export function memberInfoApplyList(data) {
  //信息修改申请
  return pos(hostPath.base, 'user/profile', data);
}
export function memberInfoApplyAdd(data) {
  //信息修改申请
  return pos(hostPath.base, 'user/profile/handle', data);
}
export function memberBatchRecharge(data) {
  //批量设置充值中心
  return pos(hostPath.base, 'user/recharge/handle', data);
}
export function memberBatchStore(data) {
  //批量设置充值中心
  return pos(hostPath.base, 'user/store/handle', data);
}
export function memberBalance(data) {
  //会员奖金
  return pos(hostPath.base, 'balance', data);
}
export function memberPwdModify(data) {
  //密码修改
  return pos(hostPath.base, 'user/password/change', data);
}
export function memberAreaAgent(data) {
  //密码修改
  return pos(hostPath.base, 'user/set/area', data);
}
export function balanceReset(data) {
  //充值结算状态
  return pos(hostPath.base, 'balance/reset', data);
}
export function balanceStatus(data) {
  //获取结算状态
  return pos(hostPath.base, 'balance/status', data);
}
export function expressCheckout(data) {
  //产品批量出库
  return pos(hostPath.base, 'express/checkout', data);
}
export function expressCheckoutAll(data) {
  //产品全部出库
  return pos(hostPath.base, 'express/checkout/all', data);
}
export function expressDeliver(data) {
  //产品批量发货
  return pos(hostPath.base, 'express/deliver', data);
}
export function expressDeliverAll(data) {
  //产品全部发货
  return pos(hostPath.base, 'express/deliver/all', data);
}
export function expressOrderLock(data) {
  //锁单
  return pos(hostPath.base, 'order/lock', data);
}
export function expressOrderLockAll(data) {
  //锁单
  return pos(hostPath.base, 'order/lock', data);
}
export function expressLock(data) {
  //锁单
  return pos(hostPath.base, 'express/lock', data);
}
export function expressLockAll(data) {
  //锁单
  return pos(hostPath.base, 'express/lock/all', data);
}
export function expressImport(data) {
  //快速导入
  return pos(hostPath.base, 'express/import', data);
}
export function expressReset(data) {
  //批量重置订单
  return pos(hostPath.base, 'express/reset', data);
}
export function pythonBalance(data) {
  //批量重置订单
  var args = ['rebalancesys', '-expectnum', data.expect_num, '-systemid', setting.systemId]; // let args = ['balance']

  return pos(hostPath.base, 'balance/exec', {
    args: args
  });
}
export function pythonNewBalance(data) {
  //批量重置订单
  var args = ['newbalance']; // let args = ['balance']

  return pos(hostPath.base, 'balance/exec', {
    args: args
  });
}
export function sendCmd(data) {
  //批量重置订单
  return pos(hostPath.base, 'balance/exec', {
    args: data
  });
}
export function fileList(data) {
  //批量重置订单
  return pos(hostPath.base, 'report', data);
}
export function clientLogin(data) {
  //汇款审核
  return pos(hostPath.base, 'user/login', data);
}
export function manualSendBonus(data) {
  //手动发放奖金
  return pos(hostPath.base, 'balance/send/manual', data);
}
export function manualUnSendBonus(data) {
  //撤销发放
  return pos(hostPath.base, 'balance/rollback', data);
}
export function memberLoginLog(data) {
  //撤销发放
  return pos(hostPath.base, 'user/login/logs', data);
}
/*********************/

export function userImport(data) {
  //用户代理
  return pos(hostPath.base, 'user/import', data);
}
export function updateGlobalCode(data) {
  //用户代理
  return pos(hostPath.base, 'switch/login-code-set', data);
}
export function getGlobalCode(data) {
  //用户代理
  return pos(hostPath.base, 'switch/login-code-get', data);
}